import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, DashboardPageContainer } from './Dashboard.style';
import { useAppDispatch } from '../../../redux/hooks';
import { signOutUser } from '../../../redux/user/userSlice';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const SignOutHandler = () => {
    dispatch(signOutUser());
    navigate('/');
  };

  return (
    <DashboardPageContainer>
      DASHBOARD
      <ButtonContainer>
        <button onClick={SignOutHandler}>Sign out</button>
      </ButtonContainer>
    </DashboardPageContainer>
  );
};

export default memo(Dashboard);
