export type Pagination = {
    itemsPerPage: number,
    totalPages: number,
    currentPage: number
}

export type PaginationRequest = {
    itemsPerPage: number,
    currentPage: number
}

export const parsePagination= (pagination: any) => {
    const responsePagination = JSON.parse(pagination);
    const parsedPagination: Pagination = { itemsPerPage: responsePagination.PageSize, totalPages: responsePagination.TotalPages, currentPage: responsePagination.PageIndex };
    return parsedPagination;
}