import styled from 'styled-components';
import { Column } from '../../../utils/layout';

export const DashboardPageContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled(Column)`
  width: 20%;
  align-self: center;
`;
