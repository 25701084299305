export const AUTH_BASE_ROUTE = `/auth`;

export const BASE_ROUTE = `/hostAdmin`;

export const DASHBOARD = `dashboard`;
export const MAP = `map`;
export const USER = `users`;
export const PREMISE = `premises`;

export const HowItWorks_Customer = '/howItWorks-customer';
export const HowItWorks_Manager = '/howItWorks-manager';
